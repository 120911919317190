import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/"
import Portfolio from "../components/portfolio/"

const IndexPage = () => (
  <Layout>
    <Seo title="Home"/>
    <Hero titleOne="Hello. I’m Fraser" titleTwo="& I Build Websites." />
    <section className="section">
      <div className="container">
        <h2>Projects I’ve Worked On</h2>
        <ul className="portfolio-list">
          <li>
            <Portfolio title="Macphie" link="https://macphie.com" />
          </li>
          <li>
            <Portfolio title="MadeBrave" link="https://madebrave.com" />
          </li>
          <li>
            <Portfolio
              title="Famous Grouse"
              link="https://thefamousgrouse.com/"
            />
          </li>
          <li>
            <Portfolio
              title="Nestlé"
              link="https://nestle.com/"
            />
          </li>
          <li>
            <Portfolio title="Kandou" link="https://Kandou.com" />
          </li>
          <li>
            <Portfolio title="Tobermory" link="https://tobermorydistillery.com/" />
          </li>
          <li>
            <Portfolio title="SEP" link="https://sep.co.uk" />
          </li>
          <li>
            <Portfolio title="Naked Grouse" link="https://nakedgrouse.com" />
          </li>
          <li>
            <Portfolio title="Bunnahabhain" link="https://bunnahabhain.com/" />
          </li>
          <li>
            <Portfolio
              title="Ardnahoe"
              link="https://ardnahoedistillery.com/"
            />
          </li>
          <li>
            <Portfolio title="CitNow" link="https://citnow.com/" />
          </li>
          <li>
            <Portfolio title="Glenwyvis" link="https://glenwyvis.com/" />
          </li>
          <li>
            <Portfolio title="Vets Now" link="https://vets-now.com/" />
          </li>
          <li>
            <Portfolio
              title="Scottish Canals"
              link="https://www.scottishcanals.co.uk/"
            />
          </li>
        </ul>
      </div>
    </section>
  </Layout>
)

export default IndexPage
