import React from "react"
import * as style from "./portfolio.module.scss"

const Portfolio = ({ title, agency, link }) => (
  <a
    href={link}
    className={style["portfolioItem"]}
    target="_blank"
    rel="noreferrer"
  >
    <div className={style["portfolioItem__container"]}>
      <h3 className="weight--light"><span>{title}</span></h3>
      <dl>
        <dt className="weight--semi-bold">Agency:</dt>
        <dd className="weight--light">{agency ? agency : "MadeBrave"}</dd>
      </dl>
    </div>
  </a>
)

export default Portfolio
